'use client';
import {
	Box,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { ImageWithFallback } from '../ImageWithFallback';

import { useClientTranslation } from '@negotium/common';
import { IPagesBasicsShow, ISocialNetworksShow } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getPageBasicByMultiplesTypes } from '../../app/lib/pagesBasic/actions';
import { getSocialNetworksPublic } from '../../app/lib/socialNetworks/actions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TelegramIcon from '@mui/icons-material/Telegram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { FacebookSvg, InstagramSvg, WholesalerSvg } from '../Svgs';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';
import ThemeBasic from 'apps/customer-portal/utils/theme';
import Image from 'next/image';
type FooterProps = {
	lng: 'es' | 'en';
};

const ICON_SOCIAL_NETWORK: { [key: string]: JSX.Element } = {
	facebook: <FacebookSvg color={ThemeBasic.palette.secondary.main} />,
	whatsapp: <WhatsAppIcon color='secondary' fontSize='large' />,
	twitter: <XIcon color='secondary' fontSize='large' />,
	instagram: <InstagramSvg color={ThemeBasic.palette.secondary.main} />,
	youtube: <YouTubeIcon color='secondary' fontSize='large' />,
	linkedin: <LinkedInIcon color='secondary' fontSize='large' />,
	tiktok: <PlayCircleIcon color='secondary' fontSize='large' />,
	discord: <SmartToyIcon color='secondary' fontSize='large' />,
	telegram: <TelegramIcon color='secondary' fontSize='large' />,
	webSite: <TravelExploreIcon color='secondary' fontSize='large' />,
};

const Footer = ({ lng }: FooterProps) => {
	const { t } = useClientTranslation(lng, ['common', 'pagesBasic']);
	const { setErrorNotification } = useNotification();

	const [pageBasicColumn2, setPageBasicColumn2] = useState<
		Array<IPagesBasicsShow>
	>([]);
	const [pageBasicColumn3, setPageBasicColumn3] = useState<
		Array<IPagesBasicsShow>
	>([]);

	const [socialNetworks, setSocialNetworks] = useState<
		Array<ISocialNetworksShow>
	>([]);

	useEffect(() => {
		Promise.all([
			getPageBasicByMultiplesTypes({
				types: ['termsShipping', 'paymentTerms', 'warrantiesRefunds', 'faqs'],
			}),
			getPageBasicByMultiplesTypes({
				types: ['privacyTerms', 'termsAndConditions'],
			}),
			getSocialNetworksPublic(),
		])
			.then(
				([
					pageBasicColumn2Response,
					pageBasicColumn3Response,
					socialNetworksPublicResponse,
				]) => {
					setPageBasicColumn2(pageBasicColumn2Response.docs);
					setPageBasicColumn3(pageBasicColumn3Response.docs);
					setSocialNetworks(socialNetworksPublicResponse.docs);
				}
			)
			.catch((error) => setErrorNotification(error.message));
	}, []);

	const renderDesktop = (
		<Grid
			container
			sx={{
				display: { md: 'block', sm: 'none', xs: 'none' },
			}}
		>
			<Grid xs={12}>
				<Grid
					container
					spacing={1}
					sx={{
						mb: 5,
					}}
				>
					<Grid item xs={12} sm={12}>
						<Box
							sx={{
								width: { xs: '40%', md: '20%' },
								padding: '5px 0px',
								display: 'flex',
								margin: {
									md: '0',
									xs: '0 ',
								},
							}}
						>
							<Link href={`/${lng}`} hrefLang={lng}>
								<ImageWithFallback
									src='/static/lacuadra-logo-white667x218.png'
									fallbackImage='/static/lacuadra-logo-white667x218.png'
									alt='logo'
									width={192}
									height={70}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Link>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Divider
							sx={{
								mt: 2,
								mb: 2,
								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={2.5}
						sx={{
							borderRight: '2px solid white',
						}}
					>
						<Link
							href={`/information/aboutUs`}
							style={{
								textDecoration: 'none',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									mt: 2.3,
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: { md: '45px', xs: '22px' },
										height: { md: '45px', xs: '22px' },
										background: 'white',
										borderRadius: '50%',
									}}
								>
									<QuestionMarkIcon
										sx={{
											color: (theme) => theme.palette.secondary?.main,
											width: { md: '25px', xs: '15px' },
											height: { md: '25px', xs: '15px' },
										}}
									/>
								</Box>
								<Typography
									variant='TitleSection'
									color='white.main'
									fontSize='clamp(13px, 3vw,1.2rem)'
									ml={1}
									sx={{
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('whatIsRelsa')}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid item xs={12} sm={2.5}>
						<Link
							href={`/information/wholesales`}
							style={{
								textDecoration: 'none',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									mt: 2.3,
									pl: 1,
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: { md: '45px', xs: '22px' },
										height: { md: '45px', xs: '22px' },
										background: 'white',
										borderRadius: '50%',
									}}
								>
									<WholesalerSvg color={ThemeBasic.palette.secondary?.main} />
								</Box>
								<Typography
									variant='TitleSection'
									color='white.main'
									fontSize='clamp(13px, 3vw,1.2rem)'
									ml={{ md: 1, xs: 1.5 }}
									sx={{
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('wholeSaler')}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						sx={{
							borderLeft: '2px solid white',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
								mt: 2.3,
								pl: 1,
							}}
						>
							<InfoIcon
								sx={{
									color: (theme) => theme.palette.white?.main,
									width: { md: '40px', xs: '25px' },
									height: { md: '40px', xs: '25px' },
									zIndex: 1,
								}}
							/>
							<Typography
								variant='TitleSection'
								color='white.main'
								ml={1}
								fontSize='clamp(13px, 3vw,1.2rem)'
							>
								{t('attentionClient')}
							</Typography>
						</Box>

						<Grid
							container
							sx={{
								width: { md: '77%', xs: '78%' },
								margin: 'auto',
							}}
						>
							{[...pageBasicColumn2, ...pageBasicColumn3].map((page) => (
								<Grid item xs={6} key={page.pagesBasicId}>
									<Link
										href={`/information/${page.pageBasicType}`}
										style={{
											textDecoration: 'none',
										}}
									>
										<Typography
											variant='BodySection'
											fontSize='clamp(10px,3vw,13px)'
											// textTransform='uppercase'
											lineHeight='1rem'
											fontWeight='bold'
											color='white.main'
											sx={{
												'&:hover': {
													textDecoration: 'underline',
												},
											}}
										>
											{t(page.pageBasicType, { ns: 'pagesBasic' })}
										</Typography>
									</Link>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						sx={{
							borderLeft: '2px solid white',
						}}
					>
						<Box sx={{}}>
							<Box display='flex' alignItems={'center'} paddingLeft={1}>
								<Typography
									variant='TitleSection'
									lineHeight='1rem'
									fontSize='clamp(13px, 3vw,1.2rem)'
									fontWeight='bold'
									color='white.main'
								>
									{t('followUs')}
								</Typography>

								<List
									sx={{
										display: 'flex',
										alignItems: 'center',
										ml: 3,
										mt: 0.5,
									}}
								>
									{socialNetworks.map((social) => (
										<ListItem
											key={social.socialNetworksId}
											alignItems='center'
											sx={{
												justifyContent: {
													sm: 'flex-start',
													xs: 'center',
												},
												width: 'max-content',
												marginBottom: 1,
												p: 1,
											}}
										>
											<Box
												sx={{
													width: { md: '38px', xs: '20px' },
													height: { md: '38px', xs: '20px' },
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													background: (theme) =>
														theme.palette.white?.main || 'white',
												}}
											>
												<Link
													href={social.url}
													style={{
														textDecoration: 'none',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													{ICON_SOCIAL_NETWORK[social.name]}
												</Link>
											</Box>
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{
								mt: 2,
								mb: 2,
								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex',
								mt: 4,
							}}
						>
							<Image
								src='/static/footer-lacuadra-d.svg'
								width={1000}
								height={400}
								alt='Footer Lacuadra'
								style={{
									width: '100%',
									height: 'auto',
								}}
							/>
						</Box>
					</Grid>

					{/* <Grid item xs={12}>
				<Typography
					variant='BodySection'
					fontSize='clamp(0.9rem,3vw,1.1rem)'
					textAlign='center'
					color='white.main'
					lineHeight={{
						xs: '1.6rem',
						md: '2.6rem',
					}}
					sx={{
						a: {
							color: (theme) => theme.palette.white?.main,
						},
					}}
				>
					<span>{` ${t(
						'allRights'
					)} _ © ${new Date().getFullYear()} _ ${t(
						'developBy'
					)} `}</span>
					<Link
						href='https://www.futurasit.com'
						passHref
						style={{
							textDecoration: 'none',
						}}
					>
						Futura Sit
					</Link>
				</Typography>
			</Grid> */}
				</Grid>
			</Grid>
		</Grid>
	);

	const renderMobile = (
		<Grid
			container
			sx={{
				display: { md: 'none', xs: 'block' },
			}}
		>
			<Grid xs={12} md={6.5}>
				<Grid
					container
					spacing={1}
					sx={{
						mb: 5,
					}}
				>
					<Grid item xs={12} sm={12}>
						<Box
							sx={{
								width: { xs: '40%', md: '20%' },
								padding: '5px 0px',
								display: 'flex',
								margin: {
									md: '0',
									xs: '0 ',
								},
							}}
						>
							<Link href={`/${lng}`} hrefLang={lng}>
								<ImageWithFallback
									src='/static/logo-white667x218.png'
									fallbackImage='/static/logo-white667x218.png'
									alt='logo'
									width={192}
									height={70}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{
								mt: 2,
								mb: 2,
								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Link
							href={`/information/aboutUs`}
							style={{
								textDecoration: 'none',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: { md: '45px', xs: '22px' },
										height: { md: '45px', xs: '22px' },
										background: 'white',
										borderRadius: '50%',
									}}
								>
									<QuestionMarkIcon
										sx={{
											color: (theme) => theme.palette.secondary?.main,
											width: { md: '25px', xs: '15px' },
											height: { md: '25px', xs: '15px' },
										}}
									/>
								</Box>
								<Typography
									variant='TitleSection'
									color='white.main'
									fontSize='clamp(13px, 3vw,1.2rem)'
									ml={1}
									sx={{
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('whatIsRelsa')}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid item xs={12} md={6}>
						<Link
							href={`/information/wholesales`}
							style={{
								textDecoration: 'none',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: { md: '45px', xs: '22px' },
										height: { md: '45px', xs: '22px' },
										background: 'white',
										borderRadius: '50%',
									}}
								>
									<WholesalerSvg color={ThemeBasic.palette.secondary?.main} />
								</Box>
								<Typography
									variant='TitleSection'
									color='white.main'
									fontSize='clamp(13px, 3vw,1.2rem)'
									ml={{ md: 1, xs: 1.5 }}
									sx={{
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('wholeSaler')}
								</Typography>
							</Box>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{
								mt: 2,
								mb: 2,
								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<InfoIcon
								sx={{
									color: (theme) => theme.palette.white?.main,
									width: { md: '40px', xs: '25px' },
									height: { md: '40px', xs: '25px' },
								}}
							/>
							<Typography
								variant='TitleSection'
								color='white.main'
								ml={1}
								fontSize='clamp(13px, 3vw,1.2rem)'
							>
								{t('attentionClient')}
							</Typography>
						</Box>

						<Grid
							container
							sx={{
								width: '87%',
								margin: 'auto',
							}}
						>
							{[...pageBasicColumn2, ...pageBasicColumn3].map((page) => (
								<Grid item xs={6} key={page.pagesBasicId}>
									<Link
										href={`/information/${page.pageBasicType}`}
										style={{
											textDecoration: 'none',
										}}
									>
										<Typography
											variant='BodySection'
											fontSize='clamp(10px,3vw,13px)'
											// textTransform='uppercase'
											lineHeight='1rem'
											fontWeight='bold'
											color='white.main'
											sx={{
												'&:hover': {
													textDecoration: 'underline',
												},
											}}
										>
											{t(page.pageBasicType, { ns: 'pagesBasic' })}
										</Typography>
									</Link>
								</Grid>
							))}
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider
							sx={{
								width: '94%',

								marginLeft: 'auto',
								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
						<Box width={'87%'} margin='auto'>
							<Box display='flex' alignItems={'center'}>
								<Typography
									width='48%'
									variant='TitleSection'
									lineHeight='1rem'
									fontSize='clamp(13px, 3vw,1.2rem)'
									fontWeight='bold'
									color='white.main'
								>
									{t('followUs')}
								</Typography>

								<List
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{socialNetworks.map((social) => (
										<ListItem
											key={social.socialNetworksId}
											alignItems='center'
											sx={{
												justifyContent: {
													sm: 'flex-start',
													xs: 'center',
												},
												width: 'max-content',
												marginBottom: 1,
												p: 1,
											}}
										>
											<Box
												sx={{
													width: { md: '38px', xs: '20px' },
													height: { md: '38px', xs: '20px' },
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													background: (theme) =>
														theme.palette.white?.main || 'white',
												}}
											>
												<Link
													href={social.url}
													style={{
														textDecoration: 'none',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													{ICON_SOCIAL_NETWORK[social.name]}
												</Link>
											</Box>
										</ListItem>
									))}
								</List>
							</Box>
						</Box>
						<Divider
							sx={{
								width: '94%',

								marginLeft: 'auto',

								borderColor: (theme) => theme.palette.white?.main,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex',
								mt: 4,
							}}
						>
							<Image
								src='/static/footer-lacuadra-m.svg'
								width={1000}
								height={400}
								alt='Footer Lacuadra'
								style={{
									width: '95%',
									height: 'auto',
									margin: 'auto',
								}}
							/>
						</Box>
					</Grid>
					{/* <Grid item xs={12}>
					<Typography
						variant='BodySection'
						fontSize='clamp(0.9rem,3vw,1.1rem)'
						textAlign='center'
						color='white.main'
						lineHeight={{
							xs: '1.6rem',
							md: '2.6rem',
						}}
						sx={{
							a: {
								color: (theme) => theme.palette.white?.main,
							},
						}}
					>
						<span>{` ${t(
							'allRights'
						)} _ © ${new Date().getFullYear()} _ ${t(
							'developBy'
						)} `}</span>
						<Link
							href='https://www.futurasit.com'
							passHref
							style={{
								textDecoration: 'none',
							}}
						>
							Futura Sit
						</Link>
					</Typography>
				</Grid> */}
				</Grid>
			</Grid>
		</Grid>
	);

	return (
		<footer>
			<Container
				maxWidth={false}
				disableGutters
				sx={{
					background: (theme) => theme.palette.secondary?.main,
					py: 8,
					minHeight: { xs: '70vh', sm: 'auto' },
				}}
			>
				<Container maxWidth='xl'>
					{renderDesktop}
					{renderMobile}
				</Container>
			</Container>
		</footer>
	);
};

export default Footer;
